export const BUTTONS = {
  it: 'REGISTRATI SUBITO!',
  uk: 'SIGN UP NOW!',
  us: 'SIGN UP NOW!',
  es: '¡REGÍSTRATE AHORA!',
  pt: 'REGISTAR AGORA!',
};

export const TEST_BUTTONS = {
  ...BUTTONS,
  it: "Registrati su Together Price per pagare Netflix meno"
}