import { graphql } from 'gatsby';
import DynamicLandingPage from '../../../../../site/src/@elegantstack/gatsby-blog-pages/containers/DynamicLanding';

export default DynamicLandingPage;

export const pageQuery = graphql`
  query allServiceLandingQuery($id: String!, $blocksPaths: [String]!) {
    allBlockContents: allBlockContent(filter: {
        page: { in: $blocksPaths } 
        identifier: { in: [
            "header",
            "heroImage",
            "heroDescription",
            "descriptionContent",
            "dynamicLanding-testimonials",
            "dynamicLanding-test-testimonials",
            "old-publications",
            "bottomDescription",
            "footer"
        ] }
    }) {
      nodes {
        ...BlockContent
      }
    }
    site {
      siteMetadata {
          locale
      }
    }
    serviceLandings: allServiceLanding(filter: { id: { eq: $id } }) {
      nodes {
        ...ServiceLanding
      }
    }
    productMetaDatas: allProductMetaData {
      nodes {
        ...ProductMetaData
      }
    }
  }
`;
