import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import { Container, Box, merge } from 'theme-ui';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import Hero from '../../../../content/components/DynamicLanding/Hero';
import DescriptionContent from '../../../../content/components/DynamicLanding/DescriptionContent';
import Publications from '../../../components/old-landing/Publications';
import HoveredImage from '../../../../content/components/shared/HoveredImage';
import Testimonials from '../../solid-ui-blocks/Testimonials/Block3';
import theme from '../../../pages/new-landing/_theme';
import { useHasBeenVisible } from '../../../../hooks/useVisibility';
import FullWidthPlaceHolder from '../../../../content/components/shared/FullWidthPlaceHolder';
import { useRef } from 'react';
import { combineText } from '../../../../functions/combineText';
import { PostBody } from '../../../../../packages/flow-ui/flow-ui-widgets/src/Post';
import ProductMetaData from '../../../../content/components/ProductMetaData';
import { isMobile } from 'react-device-detect';
import { BUTTONS, TEST_BUTTONS } from '../../../../config/buttons';

const styles = {
  pageStyle: {
    textAlign: 'center',
  },
  heroScreen: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  reviewWrapper: {
    backgroundColor: 'rgba(231, 217, 217, 0.39)',
    h3: {},
  },
  leftAlign: {
    textAlign: 'left',
  },
};

const DynamicLanding = (props) => {
  const { allBlockContents, serviceLandings, productMetaDatas } = props.data;
  const dynamicLanding = serviceLandings.nodes[0];
  const content = normalizeBlockContentNodes(allBlockContents?.nodes);
  const testimonialsRef = useRef();
  const bottomRef = useRef();
  const descriptionRef = useRef();
  const hasScrolledReason = useHasBeenVisible(testimonialsRef);
  const hasScrolledBottom = useHasBeenVisible(bottomRef);
  const hasScrolledDescription = useHasBeenVisible(descriptionRef);
  const lang = props.data.site.siteMetadata.locale;
  const isTest = dynamicLanding.notIndexed ?? false;

  const buttonText = isTest ? TEST_BUTTONS[lang] : BUTTONS[lang];

  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo
        title={dynamicLanding.seoTitle}
        keywords={dynamicLanding.keywords}
        description={dynamicLanding.metaDescription}
      />
      <ProductMetaData
        name={dynamicLanding?.service?.name}
        description={dynamicLanding.metaDescription}
        productMetaData={productMetaDatas?.nodes[0]}
      />
      <Header content={content['header']} />
      <Container sx={styles.pageStyle} mt={6}>
        <Hero
          staticContent={{
            heroImage: content['heroImage'],
            heroDescription: isTest
              ? content['descriptionContent']
              : content['heroDescription'],
          }}
          buttonText={buttonText}
          dynamicContent={dynamicLanding}
          isTest={isTest}
        />
        {hasScrolledDescription || !isMobile ? (
          <DescriptionContent
            staticContent={content['descriptionContent']}
            dynamicContent={dynamicLanding}
            isTest={isTest}
            button={{
              ...content['heroImage'].buttons[0],
              text: buttonText,
              link:
                content['heroImage'].buttons[0].link +
                (dynamicLanding?.queryParams || ''),
            }}
          />
        ) : (
          <FullWidthPlaceHolder
            reference={descriptionRef}
            customStyle={{ height: '1030px', backgroundColor: '#F3EDED' }}
          />
        )}

        <Box sx={styles.reviewWrapper} px={[2, 4]} pb='3' pt='5'>
          {hasScrolledReason ? (
            <div>
              <Testimonials
                autoplayInterval={isTest ? 15000 : undefined}
                content={
                  content[
                    isTest
                      ? 'dynamicLanding-test-testimonials'
                      : 'dynamicLanding-testimonials'
                  ]
                }
              />
              {content['old-publications'] && (
                <Publications
                  style={{ backgroundColor: 'white' }}
                  showVideo={lang === 'pt'}
                  content={content['old-publications']}
                />
              )}
            </div>
          ) : (
            <FullWidthPlaceHolder
              reference={testimonialsRef}
              customStyle={{ height: '1030px', backgroundColor: '#F3EDED' }}
            />
          )}
          {dynamicLanding.body && (
            <Box pt='4' pb='2' sx={styles.leftAlign}>
              <PostBody
                body={props?.pageContext?.modifiedData || dynamicLanding.body}
              />
            </Box>
          )}
        </Box>
        {hasScrolledBottom ? (
          <HoveredImage
            header1={
              dynamicLanding?.bottomImageDescription ||
              combineText(content['bottomDescription']?.title?.text, [
                dynamicLanding?.service?.name || '',
              ])
            }
            image={dynamicLanding.bottomImage}
            button={{
              ...content['heroImage'].buttons[0],
              text: buttonText,
              link:
                content['heroImage'].buttons[0].link +
                (dynamicLanding?.queryParams || ''),
            }}
          />
        ) : (
          <FullWidthPlaceHolder
            reference={bottomRef}
            customStyle={{ height: '500px', backgroundColor: '#3C261C' }}
          />
        )}
      </Container>
      <Divider space='5' />
      <Footer content={content?.['footer']} showTrustPilot />
    </Layout>
  );
};

export default DynamicLanding;
